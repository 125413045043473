<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="locationsFiltered"
          :items-per-page="locationsFiltered.length"
          :loading="loading"
          loading-text="Cargando los datos..."
          no-data-text="Sin datos..."
          item-key="id"
          item-class="text-center"
          disable-sort
          hide-default-footer
          must-sort
        >
          <template slot="item.action" scope="props">
            <div class="d-flex justify-center my-3">
              <v-menu bottom offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                    <v-icon size="20px">mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleClickEdit(props.item)">
                    <v-list-item-content class="font-weight-medium">
                      <div class="d-flex align-center">
                        <v-icon size="18px" class="mr-3"
                          >mdi-square-edit-outline</v-icon
                        >
                        <div class="subtitle-2 font-weight-regular">
                          Modificar
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="clickDelete(props.item)"
                    :disabled="locations.length === 1"
                  >
                    <v-list-item-content class="font-weight-medium">
                      <div class="d-flex align-center">
                        <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                        <div class="subtitle-2 font-weight-regular">
                          Eliminar
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LocationList",
  props: ["loading", "searchInput", "locations"],
  data() {
    return {
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Dirección", value: "address" },
        { text: "Teléfono", value: "phoneNumber" },
        { text: "", value: "action", align: "center" },
      ],
    };
  },
  computed: {
    locationsFiltered() {
      return this.locations.filter((e) => {
        if (!this.searchInput) return e;
        else {
          return (
            e?.name?.toLowerCase()?.includes(this.searchInput.toLowerCase()) ||
            e?.address?.toLowerCase()?.includes(this.searchInput.toLowerCase())
          );
        }
      });
    },
  },
  methods: {
    handleClickEdit(item) {
      this.$emit("click-edit", item);
    },
    clickDelete(item) {
      this.$emit("click-delete", item);
    },
  },
};
</script>
