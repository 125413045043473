<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Sedes</h2>
        <v-divider class="mt-2 mb-8"></v-divider>
        <div class="d-md-flex align-center">
          <div class="mr-5">
            <v-text-field
              v-model="searchInput"
              label="Buscar"
              append-icon="mdi-magnify"
              type="text"
              clearable
              dense
              outlined
            ></v-text-field>
          </div>
          <div class="mb-6">
            <v-btn
              @click="handleClickNew"
              block
              small
              color="primary"
              depressed
            >
              Nuevo
            </v-btn>
          </div>
        </div>

        <LocationList
          :locations="locations"
          :searchInput="searchInput"
          @click-edit="handleClickEdit"
          @click-delete="handleClickDelete"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="showFormDialog"
      width="800"
      persistent
      @keydown.esc="showFormDialog = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-btn text icon @click="showFormDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <LocationForm
          ref="LocationFormRef"
          @submited="showFormDialog = false"
          :action="formAction"
        />
      </v-card>
    </v-dialog>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteItem"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import LocationForm from "../components/Location/LocationForm.vue";
import LocationList from "../components/Location/LocationList.vue";
import WarningDialog from "../components/WarningDialog.vue";

export default {
  name: "Locations",
  components: {
    LocationForm,
    LocationList,
    WarningDialog,
  },
  data() {
    return {
      currentItem: null,
      searchInput: "",
      formAction: "create",
      showFormDialog: false,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters(["locations", "currentUser"]),
  },
  methods: {
    ...mapMutations(["setAlert", "removeLocation"]),
    async deleteItem() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/location/${this.currentItem.id}`, {
          data: {
            doctor: this.currentUser.doctor,
          },
        });
        const { item, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.removeLocation(item);
        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
      }
    },
    handleClickNew() {
      this.formAction = "create";
      this.showFormDialog = true;
      this.$nextTick(() => {
        this.$refs.LocationFormRef.setFormValues(null);
      });
    },
    handleClickEdit(item) {
      this.formAction = "update";
      this.showFormDialog = true;
      this.$nextTick(() => {
        this.$refs.LocationFormRef.setFormValues(item);
      });
    },
    handleClickDelete(item) {
      this.currentItem = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea remover "${this.currentItem.name}"?`;
      this.$refs.WarningDialogRef.show = true;
    },
  },
};
</script>
