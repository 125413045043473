<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="action === 'create' ? createItem() : editItem()"
    >
      <v-container>
        <Title
          :title="action === 'create' ? 'Crear sede' : 'Actualizar sede'"
          class="mb-8"
        />
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              type="text"
              label="Nombre *"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="address"
              type="text"
              label="Dirección *"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="phoneNumber"
              type="text"
              label="Número telefónico *"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              small
              color="primary"
              class="pa-5"
              type="submit"
              depressed
              :disabled="!validation"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import Title from "../Title.vue";

export default {
  name: "LocationForm",
  components: {
    Title
  },
  props: ["action"],
  data() {
    return {
      id: "",
      name: "",
      address: "",
      phoneNumber: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    validation() {
      return !(!this.name || !this.address || !this.phoneNumber);
    },
  },
  methods: {
    ...mapMutations(["setAlert", "pushLocation", "updateLocation"]),
    async createItem() {
      try {
        this.loading = true;
        const itemToCreate = {
          name: this.name,
          address: this.address,
          phoneNumber: this.phoneNumber,
          doctor: this.currentUser.doctor,
        };
        const res = await axios.post("/api/location", itemToCreate);
        const { item, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.pushLocation(item);
        this.$emit("item-created", item);
        this.$emit("submited");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async editItem() {
      try {
        this.loading = true;
        const itemToUpdate = {
          id: this.id,
          name: this.name,
          address: this.address,
          phoneNumber: this.phoneNumber,
          doctor: this.currentUser.doctor,
        };
        const res = await axios.put(`/api/location/${this.id}`, itemToUpdate);
        const { item, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.updateLocation(item);
        this.$emit("item-updated", item);
        this.$emit("submited");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    setFormValues(obj) {
      this.id = obj?.id || null;
      this.name = obj?.name || "";
      this.address = obj?.address || "";
      this.phoneNumber = obj?.phoneNumber || "";
    },
  },
};
</script>
